<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbooks <span>Content Headline</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-content-headline-form" class="playbook_headline">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Content Headline</h5>
                            <label for="show_content_headline_v2" class="switch_option capsule_btn">
                                <input type="checkbox" id="show_content_headline_v2" :true-value="1" :false-value="0" v-model="form.display_step_headline" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Headline</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.step_headline }">
                                    <Field autocomplete="off" name="step_headline" type="text" placeholder="ex: Playbook Content" v-model="form.step_headline" />
                                </div>
                                <ErrorMessage name="step_headline" class="text-danger" />
                            </div>
                        </div>
                        <h3 class="sub_header2 mt-3">Headline Position</h3>
                        <div class="edit_section py-2">
                            <div class="capsule_elm border-bottom">
                                <h5>Left Align?</h5>
                                <label for="left" class="switch_option capsule_btn">
                                    <input type="radio" id="left" value="left" v-model="form.step_headline_position" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="capsule_elm border-bottom">
                                <h5>Center Align?</h5>
                                <label for="center" class="switch_option capsule_btn">
                                    <input type="radio" id="center" value="center" v-model="form.step_headline_position" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="capsule_elm">
                                <h5>Right Align?</h5>
                                <label for="right" class="switch_option capsule_btn">
                                    <input type="radio" id="right" value="right" v-model="form.step_headline_position" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="playbookUpdateLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="playbookUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookUpdateLoader"></i> {{ playbookUpdateLoader ? 'Updating' : 'Update' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Playbook Content Headline',

        data () {
            return {
                form: {
                    display_step_headline: 0,
                    step_headline: '',
                    step_headline_position: 'left',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            selectedPlaybook () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            playbookUpdateLoader: state => state.playbookModule.playbookUpdateLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePlaybookSetting: 'playbookModule/updatePlaybookSetting',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    playbook_id: vm.selectedPlaybook.id,
                    display_step_headline: vm.selectedPlaybook.display_step_headline ? vm.selectedPlaybook.display_step_headline : 0,
                    step_headline: vm.selectedPlaybook.step_headline ? vm.selectedPlaybook.step_headline : '',
                    step_headline_position: vm.selectedPlaybook.step_headline_position ? vm.selectedPlaybook.step_headline_position : 'left',
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError  = setFieldError;

                vm.updatePlaybookSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>
<style scoped>
    .playbook_headline{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 99px);
    }
</style>
